import React from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import * as styles from './iframe.module.css';

interface Props {
    code: {
        value: string;
    };
}
const IFrame: React.FC<Props> = ({ code }) => {
    let html = code.value;
    html = html.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    html = html.replace(/\n/g, '');
    let processedHtmlTags = parse(html);
    return (
        <>
            <div className={styles.reviews}>{processedHtmlTags}</div>
        </>
    );
};
export default IFrame;
export const fragmentIFrame = graphql`
    fragment IFrame on kontent_item_component___iframe {
        elements {
            code {
                value
            }
        }
    }
`;
